export const nlExcelImport = {
  uploadStep: {
    title: 'Bestand uploaden',
    manifestTitle: 'Gegevens die we verwachten:',
    manifestDescription:
      '(U krijgt de kans om kolommen te hernoemen of te verwijderen in de volgende stappen)',
    maxRecordsExceeded: (maxRecords: string) =>
      `Te veel records. Maximaal ${maxRecords} toegestaan`,
    dropzone: {
      title: 'Upload .xlsx, .xls of .csv bestand',
      errorToastDescription: 'upload afgewezen',
      activeDropzoneTitle: 'Bestand hier neerzetten...',
      buttonTitle: 'Bestand selecteren',
      loadingTitle: 'Verwerken...',
    },
    selectSheet: {
      title: 'Selecteer het te gebruiken blad',
      nextButtonTitle: 'Volgende',
    },
  },
  selectHeaderStep: {
    title: 'Selecteer koptekst rij',
    nextButtonTitle: 'Volgende',
  },
  matchColumnsStep: {
    title: 'Kolommen matchen',
    nextButtonTitle: 'Volgende',
    userTableTitle: 'Uw tabel',
    templateTitle: 'Zal worden',
    selectPlaceholder: 'Selecteer kolom...',
    ignoredColumnText: 'Kolom genegeerd',
    subSelectPlaceholder: 'Selecteer...',
    matchDropdownTitle: 'Match',
    unmatched: 'Niet overeenkomend',
    duplicateColumnWarningTitle: 'Andere kolom niet geselecteerd',
    duplicateColumnWarningDescription: 'Kolommen kunnen niet dupliceren',
  },
  validationStep: {
    title: 'Gegevens valideren',
    nextButtonTitle: 'Bevestigen',
    noRowsMessage: 'Geen gegevens gevonden',
    noRowsMessageWhenFiltered: 'Geen gegevens met fouten',
    discardButtonTitle: 'Geselecteerde rijen verwijderen',
    filterSwitchTitle: 'Toon alleen rijen met fouten',
  },
  alerts: {
    confirmClose: {
      headerTitle: 'Importflow verlaten',
      bodyText:
        'Weet u het zeker? Uw huidige informatie wordt niet opgeslagen.',
      cancelButtonTitle: 'Annuleren',
      exitButtonTitle: 'Flow verlaten',
    },
    submitIncomplete: {
      headerTitle: 'Fouten gedetecteerd',
      bodyText:
        'Er zijn nog rijen die fouten bevatten. Rijen met fouten worden genegeerd bij het indienen.',
      bodyTextSubmitForbidden: 'Er zijn nog rijen met fouten.',
      cancelButtonTitle: 'Annuleren',
      finishButtonTitle: 'Indienen',
    },
    unmatchedRequiredFields: {
      headerTitle: 'Niet alle kolommen overeenkomend',
      bodyText:
        'Er zijn verplichte kolommen die niet overeenkomen of genegeerd worden. Wilt u doorgaan?',
      listTitle: 'Kolommen niet overeenkomend:',
      cancelButtonTitle: 'Annuleren',
      continueButtonTitle: 'Doorgaan',
    },
    toast: {
      error: 'Fout',
    },
  },
};
