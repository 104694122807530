import { ReactElement } from 'react';
import { LocaleObject } from 'yup';
import { nl } from 'yup-locales';

export const yupNlLocale: LocaleObject & {
  mixed: LocaleObject['mixed'] & {
    phone: (args: Record<string, string | ReactElement>) => string;
    vatNumber: (args: Record<string, string | ReactElement>) => string;
  };
} = {
  ...nl,
  mixed: {
    ...nl.mixed,
    phone: (args: Record<string, string | ReactElement>): string => {
      const path = args.path || 'this';

      return `${path} moet een geldig telefoonnummer zijn.`;
    },
    vatNumber: (args: Record<string, string | ReactElement>): string => {
      const path = args.path || 'this';

      return `${path} moet een geldig btw nummer zijn.`;
    },
  },
};
